<template>
  <div class="point-rule-wrapper">
    <div class="rule-item"
      :class="{'border-bottom-1px': index < creditRules.length - 1}"
      v-for="rule in creditRules"
      :key="rule.idCcCreditRule">
      <div class="rule">
        <svg aria-hidden="true" v-if="rule.code === '1001'">
          <use xlink:href="#iconGroup"></use>
        </svg>
        <svg aria-hidden="true" v-else-if="rule.code === '1002'">
          <use xlink:href="#iconshangchuan"></use>
        </svg>
        {{rule.name}}
      </div>
      <div class="point">积分+{{rule.amount}}</div>
    </div>
    <!-- <div class="rule-item">
      <div class="rule">
        <svg aria-hidden="true">
          <use xlink:href="#iconshangchuan"></use>
        </svg>
        报料成功
      </div>
      <div class="point">积分+10</div>
    </div> -->
  </div>
</template>

<script>
import API from '@/api/index'
const { getCreditRules } = API

export default {
  name: 'pointRule',
  data () {
    return {
      creditRules: []
    }
  },
  created () {
    this.getCreditRules()
  },
  methods: {
    // 获取积分规则
    async getCreditRules () {
      let res = await getCreditRules()
      if (res.success) {
        this.creditRules = res.data
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .point-rule-wrapper{
    padding: 0 24px;
    .rule-item{
      display: flex;
      justify-content: space-between;
      padding: 40px 0;
      color: #333;
      font-size: 32px;
      .rule{
        display: flex;
        align-items: center;
      }
      svg{
        width: 40px;
        height: 32px;
        color: #677881;
        margin-right: 20px;
      }
      .point{
        font-size: 26px;
        color: #FC5E25;
      }
    }
  }
</style>
